@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import '~antd/dist/antd.css';

*{
    font-family: 'Open Sans', sans-serif;
}

body{
    background: #f8f9fa;
}

[hidden]{
    display: none !important;
}

#navigation{
    display: flex;
    gap: 35px;
    position: absolute;
    padding: 50px;
    width: 100vw;
    top: 0;
    justify-content: center;
    background: white;
    box-shadow: 0 2px 7px #eee;
}

#logo{
    position: absolute;
    top: 20px;
    left: 150px;
    width: 100px;
}

#forter-logo{
    position: absolute;
    top: 20px;
    left: 50px;
    width: 100px;
}

h1, h2, h3, h4, h5, h6, h7{
    padding: 0;
    margin: 0;
}

#navigation ~ div{
    margin-top: 150px;
    padding: 0 50px;
}

#register-wrapper{
    margin: 50px 0 20px;
    display: flex;
    justify-content: center;
}

#register{
    background: #55aad9;
    border-radius: 1000px;
    padding: 10px 100px;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    transition: 0.3s;
}

#register-form{
    display: grid;
    justify-content: center;
}

#register-form h2{
    margin: 30px 0 10px;
}

.insideStyle{
    background: white;
    padding: 20px 50px;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 20px;
    border: 8px solid rgb(255 255 255 / 40%)
}

.insideStyle-2{
    position: absolute;
    top: 20px;
    left: 20px;
    gap: 20px;
    display: grid;
}

.insideStyle-2-inner{
    background: white;
    padding: 20px 50px;
    border-radius: 20px;
    border: 8px solid rgb(255 255 255 / 40%)
}

#join-whatsapp{
    background: green;
    display: flex;
    place-content: center;
    color: white;
    border-radius: 200px;
}

#register:hover {
    background: #1e5ba2;
}

#details h1{
    padding: 0;
}

#navigation a{
    text-decoration: none;
    color: black;
    transition: 0.3s;
    font-size: 16px;
}

.radio-input-wrapper{
    margin-bottom: 20px;
}

.amount-wrapper{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.amount-wrapper input{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 350px;
}

.text-input-wrapper, .date-input-wrapper, .time-input-wrapper, .checkbox-input-wrapper, .amount-wrapper-with-label{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 500px;
}

.text-input-wrapper label, .radio-input-wrapper label, .checkbox-input-wrapper label, .date-input-wrapper label, .time-input-wrapper label, .amount-wrapper-with-label label{
    text-transform: uppercase;
    font-size: 12px;
    color: #495057;
}

.text-input-wrapper input, .amount-wrapper input, .amount-wrapper .ant-select-selector{
    background: #e9ecef !important;
    border: none !important;
    border-radius: 200px !important;
    margin: 5px 0 0 !important;
    padding: 5px 10px !important;
}

.amount-wrapper .ant-select-selector{
    align-items: center;
}

.ant-radio-button-wrapper{
    background: #e9ecef;
}

.radio-input-wrapper-inner div:first-child .ant-radio-button-wrapper{
    border-radius: 50px 0 0 50px !important;
}

.radio-input-wrapper-inner div:last-child .ant-radio-button-wrapper{
    border-radius: 0 50px 50px 0 !important;
}

.checkbox-input-wrapper-inner label{
    text-transform: unset;
    font-size: 16px;
    color: #212529;
}

.checkbox-input-wrapper{
    margin-bottom: 20px;
}

.radio-input-wrapper-inner{
    display: flex;
}


#home .content{
    padding: 25px 0;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02em;
    /*text-align: center;*/
}

#destination h2{
    font-size: 24px;
    margin: 10px 0 20px;
}

#destination .content{
    padding: 50px 0;
    font-size: 16px;
}


.agenda-items{
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 50px;
    margin: 50px auto;
}

.agenda-day{
    display: flex;
    flex-direction: column;
    position: relative;
}


.agenda-item-legend{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #212529;
    color: #f8f9fa;
}


.agenda-day-label{
    text-transform: uppercase;
    color: #495057;
    font-size: 14px;
}

.agenda-day-date{
    color: #1864ab;
    text-transform: uppercase;
    font-size: 14px;
}

.agenda-item{
    background: white;
    margin: 20px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 10px 20px #f1f3f5;
    position: relative;
}

.agenda-item .legend-item-circle{
    position: absolute;
    top: 15px;
    right: 10px;
    outline: 2px solid white;
}

.agenda-item-label{
    text-transform: uppercase;
    font-size: 13px;
    color: #868e96;
}

.agenda-item-time{
    text-transform: uppercase;
    font-size: 13px;
    color: #1864ab;
}

#submit-button{
    margin: 50px 0;
    width: 100%;
    border: 0;
    padding: 6px 0;
    background: #1b7ed6;
    color: white;
    border-radius: 501px;
    font-size: 17px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
}

#submit-button:hover{
    background: #0f5ea4;
}

.date-input-wrapper .ant-picker{
    background: #e9ecef;
    border: none;
    border-radius: 20px;
    width: 500px;
}

.time-input-wrapper .ant-picker{
    background: #e9ecef;
    border: none;
    border-radius: 20px;
    width: 500px;
}

.ant-radio-button-wrapper{
    border: none !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled{
    background: #e7f5ff;
}

span[required]{
    color: red;
}

#legend{
    display: grid;
    grid-auto-flow: column;
    width: max-content;
    gap: 40px;
}

.legend-item{
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 20px;
    grid-template-columns: 10px max-content;
    gap: 10px;
    justify-content: start;
    align-items: center;
}

.legend-item-circle{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

#navigation [href="#/register"]{
    color: #1b7ed6 !important;
}

#success-page{
    padding: 50px !important;
}

#success-page h2, #success-page h3{
    margin: 30px 0 10px;
}

#book-your-flight-wrapper{
    display: grid;
    justify-content: center;
    margin: 50px 0;
}

#book-your-flight{
    width: 400px;
    height: 40px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
    background: #e9ecef;
    color: black;
    font-size: 16px;
}

#book-your-flight:hover{
    background: #dee2e6;
}

#success-page tr{
    height: 35px;
}

#success-page tr td{
    font-size: 16px;
}

#success-page tr td:first-child{
    width: 270px;
    color: #868e96;
}

#success-page tr td:last-child{
    font-weight: bold;
    margin-left: 20px;
    color: #1864ab;
}

.tab-text > div {
    display: grid;
    gap: 10px;
}

.tab-text > div img {
    margin-right: 5px;
}

.faq-wrapper{
    width: 500px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 10px 20px #e9ecef;
}

.faq-wrapper{
    letter-spacing: -0.5px;
    font-size: 15px;
}

#faq .content{
    padding: 50px 0px;
    display: grid;
    justify-content: center;}

#activities .content{
    padding: 50px 0;
}

#packing-list{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin: 50px 0;
}

.packing-list-item{
    width: 200px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 10px 20px #e9ecef;
    display: grid;
    justify-items: center;
    text-align: center;
    gap: 24px;
    height: 128px;
}

.packing-list-item span{
    place-self: center;
    font-size: 12px;
    text-transform: uppercase;
}

#activities .tab-content{
    display: grid;
}

#activities .tab-img{
    height: 400px;
    place-self: center;
    margin: 50px 0;
}

.agenda-item-time, .agenda-item-location, .agenda-item-description{
    display: flex;
    gap: 5px;
    place-items: center start;
}

.agenda-item-time img, .agenda-item-location img, .agenda-item-description img{
    width: 18px;
}

a .packing-list-item span{
    color: #098920 !important;
}

#calendar-fab{
    position: fixed;
    bottom: 30px;
    left: 30px;
    background: white;
    outline: 3px solid #1b7ed6;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 10px 20px #e9ecef;
    cursor: default !important;
}

#calendar-fab img{
    width: 32px;
    height: 32px;
}

#reminders{
    display: flex;
    justify-content: center;
    gap: 50px;
}

#reminders img{
    width: 64px;
    height: 64px;
}


/*Tooltip*/
/*
No JS Required
Example HTML:
<div class="t-top t-sm" data-tooltip="XXX">XXX</div>
Hide:
<div class="t-hide" data-tooltip="Im hidden tooltip"></div>
<div class="t-hide" data-tooltip=""></div>
Show:
<div class="" data-tooltip="Im not a hidden tooltip"></div>
Simply choose your preferred Content, Alignment, and Size.
Content:
  * data-tooltip='XXX'
Alignment:
  * Top = t-top
  * Right = t-right
  * Bottom = t-bottom
  * Left = t-left
Size:
  * Extra Small = t-xs
  * Small = t-sm
  * Medium = t-md
  * Large = t-lg
  * Extra Large = t-xl
*/

[data-tooltip]:not(.t-hide) {
    position: relative;
    /********** Default Behavior ********* */
    /********** Timeout Variant ********* */
    /********** Tooltip Sizes ********* */
    /********** Top Tooltip ********* */
    /********** Right Tooltip ********* */
    /********** Bottom Tooltip ********* */
    /********** Left Tooltip ********* */
}

[data-tooltip]:not(.t-hide):before,
[data-tooltip]:not(.t-hide):after {
    position: absolute;
    /*visibility: hidden;*/
    /*opacity: 0;*/
    pointer-events: none;
    transition: all 0.15s cubic-bezier(0.5, 1, 0.25, 1);
    z-index: 999;
    text-transform: none !important;
}

[data-tooltip]:not(.t-hide):before {
    padding: 5px;
    width: max-content;
    border-radius: 3px;
    background: #212529;
    color: #f8f9fa;
    content: attr(data-tooltip);
    text-align: center;
    font-size: var(--font-size-small);
    font-weight: normal;
    line-height: 1.2;
}

[data-tooltip]:not(.t-hide):after {
    border: 8px solid transparent;
    width: 0;
    content: '';
    font-size: 0;
    line-height: 0;
}

[data-tooltip]:not(.t-hide):before,
[data-tooltip]:not(.t-hide):after {
    visibility: visible;
    opacity: 1;
}

[data-tooltip]:not(.t-hide).t-xl:before {
    width: 200px;
}

[data-tooltip]:not(.t-hide).t-lg:before {
    width: 170px;
}

[data-tooltip]:not(.t-hide).t-md:before {
    width: 140px;
}

[data-tooltip]:not(.t-hide).t-sm:before {
    width: 110px;
}

[data-tooltip]:not(.t-hide).t-xs:before {
    width: 80px;
}

[data-tooltip]:not(.t-hide).t-auto:before {
    width: max-content;
    max-width: 300px;
    padding-right: 10px;
    padding-left: 10px;
}

[data-tooltip]:not(.t-hide).t-top:before {
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    transform: translateX(-50%);
}

[data-tooltip]:not(.t-hide).t-top:after {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 8px solid #212529;
    border-bottom: none;
}

[data-tooltip]:not(.t-hide).t-top:before,
[data-tooltip]:not(.t-hide).t-top:after {
    transform: translateX(-50%) translateY(-5px);
}

[data-tooltip]:not(.t-hide).t-right:before {
    top: 50%;
    left: 100%;
    margin-left: 5px;
    transform: translateY(-50%);
}

[data-tooltip]:not(.t-hide).t-right:after {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-right: 8px solid #212529;
    border-left: none;
}

[data-tooltip]:not(.t-hide).t-right:before,
[data-tooltip]:not(.t-hide).t-right:after {
    transform: translateX(5px) translateY(-50%);
}

[data-tooltip]:not(.t-hide).t-bottom:before {
    top: 100%;
    left: 50%;
    margin-top: 5px;
    transform: translateX(-50%);
}

[data-tooltip]:not(.t-hide).t-bottom:after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 8px solid #212529;
    border-top: none;
}

[data-tooltip]:not(.t-hide).t-bottom:before,
[data-tooltip]:not(.t-hide).t-bottom:after {
    transform: translateX(-50%) translateY(5px);
}

[data-tooltip]:not(.t-hide).t-left:before {
    top: 50%;
    right: 100%;
    margin-right: 5px;
    transform: translateY(-50%);
}

[data-tooltip]:not(.t-hide).t-left:after {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-left: 8px solid #212529;
    border-right: none;
}

[data-tooltip]:not(.t-hide).t-left:before,
[data-tooltip]:not(.t-hide).t-left:after {
    transform: translateX(-5px) translateY(-50%);
}

[data-tooltip="undefined"], [data-tooltip="null"], [data-tooltip=""]{
    pointer-events: none;
}
